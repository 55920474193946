export abstract class Logger {
  abstract debug(message: string, ...meta: LogMeta): void
  abstract info(message: string, ...meta: LogMeta): void
  abstract error(message: string, ...meta: LogMeta): void
  abstract warn(message: string, ...meta: LogMeta): void
  abstract log(message: string, ...meta: LogMeta): void

  abstract isSilent(): boolean
  abstract setSilent(silent: boolean): void
}

/**
 * You can provide additional meta with a log message
 * An error will be serialised into an {error} object in JSON
 * Objects will be merged into the JSON log
 */
export type LogMeta = [Error] | LogAttributes[]

export type LogLevel = keyof Pick<Console, 'warn' | 'error' | 'info' | 'debug'>

export interface LogAttributes extends Record<string, unknown> {
  /**
   * error can be passed directly to the logger, or in a nested error object
   * log monitoring is wired to support both
   */
  error?: Error
}
